import React from 'react'
import { Layout } from 'layouts'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import remark from 'remark'
import remarkHTML from 'remark-html'
import { SEO, BackgroundImage } from 'components'
import { SEOTitles } from '../../config/seo'
import ReCAPTCHA from 'react-google-recaptcha'

const Container = styled.div`
  margin: 0 auto;
  width: 100vw;
  display: flex;
`

const FormWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 3.5rem 1rem 2rem;

  @media (min-width: 900px) {
    width: 50%;
    padding: 3.5rem 4rem 2rem;
  }
`
const IntroTextWrapper = styled.div`
  margin: 0 1%;
  max-width: 600px;

  @media (max-width: 400px) {
    word-break: break-word;
  }
`

const TopInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
`

const InputWrapper = styled.div`
  border-radius: 4px;
  width: 100%;

  label {
    display: none;
  }


  input,
  textarea {
    padding: 20px;
    width: calc(100% - 40px);
    border: 0;
    background-color: #494949;
    color: white;
    border-radius: 4px;
    font-size: 1rem;
  }

  textarea {
    min-height: 14rem;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 1rem;
  }
`

const SubmitButton = styled.button`
  float: right;
  padding: 7px 35px;
  margin: 0 1% 1rem;
  border-radius: 60px;
  display: block;
  background-color: #e53132;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06), 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  border: none;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.09);
  }

  &[disabled] {
    cursor: not-allowed;

    &:hover {
      opacity: 0.3
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    float: none;
    text-align: center;
  }
`

const RecaptchaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const ContactUs = ({ data }) => {
  const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef(null)
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true)
  const [recaptchaToken, setRecaptchaToken] = React.useState(null)
  const [isError, setIsError] = React.useState(false)

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaToken,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  return (
    <Layout>
      <SEO
        title={SEOTitles.contactUs.title}
        description={SEOTitles.contactUs.description}
        pathname="contact-us"
      />
      <Container>
        <BackgroundImage />
        <FormWrapper>
          <IntroTextWrapper
            dangerouslySetInnerHTML={{
              __html: toHTML(data.markdownRemark.frontmatter.text),
            }}
          />
          <form
            name="contact-us"
            method="POST"
            data-netlify="true"
            data-netlify-recaptcha="true"
            netlify-honeypot="bot-field"
            action="/thank-you"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact-us" />
            <p className="hidden">
              <label>
                Don’t fill this out if you're human: <input name="bot-field" />
              </label>
            </p>

            <TopInputContainer>
              <InputWrapper>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  placeholder="Name *"
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email *"
                  onChange={handleChange}
                  required
                />
              </InputWrapper>
            </TopInputContainer>
            <InputWrapper>
              <label htmlFor="message">Message</label>
              <textarea
                placeholder="Your message here *"
                type="text"
                name="message"
                onChange={handleChange}
                required
              />
            </InputWrapper>
            <RecaptchaWrapper>

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              size="normal"
              id="recaptcha-google"
              onChange={(res) => {
                
                setRecaptchaToken(res)
                setIsError(false)
                setIsButtonDisabled(false)}}
                onErrored={() => {
                  setIsError(true)
                }}
              />
              </RecaptchaWrapper>
            <SubmitButton type="submit" disabled={isButtonDisabled || isError || !recaptchaToken}>
              Send Message
            </SubmitButton>
          </form>
        </FormWrapper>
      </Container>
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/pages/contact-us.md/" }) {
      html
      frontmatter {
        text
      }
    }
  }
`
